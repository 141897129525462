<template>
  <div class="main">
    <img class="myInvite-bg" src="@/assets/image/myInvite-bg.png" />
    <div class="content">
      <div class="card-box">
        <div class="line">
          <div class="line-c"></div>
        </div>
        <div class="card-c-box">
          <div class="img-box">
            <img src="@/assets/image/myInvite-icon1.png" />
            <div class="name">分享链接 <br />给您好友</div>
          </div>
          <img class="myInvite-arrow" src="@/assets/image/myInvite-arrow.png" />
          <div class="img-box">
            <img src="@/assets/image/myInvite-icon2.png" />
            <div class="name">好友注册 <br />成为会员</div>
          </div>
          <img class="myInvite-arrow" src="@/assets/image/myInvite-arrow.png" />
          <div class="img-box">
            <img src="@/assets/image/myInvite-icon3.png" />
            <div class="name">好友赚钱 <br />你得奖励</div>
          </div>
        </div>
      </div>

      <div class="card-box2">
        <div class="card-box2-title">我的邀请</div>
        <div class="invited-box">
          <div class="invited-box-left">
            <div class="number">
              <span>{{ friendCount }}</span
              >人
            </div>
            <div class="name">已邀好友</div>
          </div>
          <div class="invited-box-line"></div>
          <div class="invited-box-right">
            <div class="number">
              <span>{{ moneyCount }}</span
              >元
            </div>
            <div class="name">已邀好友</div>
          </div>
        </div>
        <div class="invited-list-title">
          <img
            class="invited-list-img"
            src="@/assets/image/invited-list-left.png"
          />
          <div class="name">邀请榜单</div>
          <img
            class="invited-list-img"
            src="@/assets/image/invited-list-right.png"
          />
        </div>
        <div class="invited-list">
          <div class="invited-list-herder">
            <div class="left">
              <div class="paiming">排名</div>
              <div class="user-id">用户ID</div>
            </div>
            <div class="right">邀请人数</div>
          </div>
          <div
            class="invited-list-item"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="left">
              <div class="paiming">
                <img
                  v-if="index < 3"
                  :src="require(`@/assets/image/no${index}.png`)"
                />
                <span v-else>{{ index + 1 }}</span>
              </div>
              <div class="user-id">
                <img :src="item.avatarUrl" @error="error" />{{ item.userName }}
              </div>
            </div>
            <div class="right">{{ item.amount }}</div>
          </div>
          <van-empty v-if="list.length === 0" description="暂无数据" />
        </div>
      </div>

      <div class="card-box2 card-box3">
        <div class="card-box2-title">邀请方式</div>

        <div class="card-box-name">邀请方式一</div>
        <div class="copy-box">
          <div class="copy-content">{{ this.http }}</div>
          <div class="copy" @click="copyText">一键复制</div>
        </div>
        <div class="card-box-name">邀请方式二</div>
        <img class="QRCode" :src="imgUrl" />
        <div class="tips">（我们将为您生成专属二维码下载发给好友）</div>
      </div>

      <div class="card-box2 card-box3">
        <div class="card-box2-title">活动介绍</div>
        <p>1.通过推广链接、二维码、微信、微博等方式邀请好友注册速调吧。</p>
        <p>
          2.您的好友第一次完成调查，您将立即获得一次
          性的2元推荐奖励（包含：k调查、Y调查、G调查
          P调查（S调查除外）），同时您的好友今后每完成一次调查，您都可获得20%的提成。例如：您推荐好友“小调”完成注册速调吧，“小调”第一次完成一份30元的调查，您可以获得2元奖励+6元的提成奖励。
        </p>
        <p>
          3.如果您的好友超过6个月没有参与任何问卷，为避免资源闲置，账户将会视为休眠用户，并与您断开好友关系，您也无法获得奖励。
        </p>
        <p>
          4.活动日期：2023年1月1至2023年12月31日。注意：活动期间内奖励翻倍，活动结束后奖励恢复正常。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode2 from "qrcode";
export default {
  data() {
    return {
      userid: null,
      friendCount: 0,
      moneyCount: 0,
      list: [],
      http: "https://www.sudiaoba.com/wechat/register.aspx",
      imgUrl: null
    };
  },
  methods: {
    error(e) {
      console.log("111e", e);
      e.target.src = require("@/assets/image/default.gif");
    },
    async getRecommend() {
      const res = await this.$http.get(
        `/api/v1/Accounts/${this.userid}/Recommend`,
        {
          params: {
            id: this.userid
          }
        }
      );

      if (res.status === 200) {
        this.friendCount = res.data.friendCount;
        this.moneyCount = res.data.moneyCount;
      }
    },
    async getRanking() {
      const res = await this.$http.get(`/api/v1/Ranking`);

      if (res.status === 200) {
        if (res.data.list) {
          this.list = res.data.list;
          console.log("this.list", this.list);
        }
      }
    },

    async copyText() {
      try {
        await navigator.clipboard.writeText(this.http);
        this.$toast("复制成功");
      } catch (err) {
        this.$toast("复制失败");
      }
    },
    qrCodeBase64(url) {
      return new Promise(resolve => {
        QRCode2.toDataURL(url).then(url => {
          resolve(url);
        });
      });
    }
  },
  created() {
    if (localStorage.getItem("userid")) {
      this.userid = localStorage.getItem("userid");
    } else {
      this.$router.push("/mobile");
    }
    this.http = `https://www.sudiaoba.com/wechat/register.aspx?uid=${this.userid}`;

    this.getRecommend();
    this.getRanking();
    this.qrCodeBase64(this.http).then(res => {
      this.imgUrl = res;
    });
  }
};
</script>
<style lang="scss" scoped>
.main {
  width: 100vw;
  min-height: calc(100vh - 33px);
  box-sizing: border-box;
  background: linear-gradient(180deg, #ff3815 0%, #ffeee2 27%, #fff9f5 100%);
  padding-bottom: 33px;
  .myInvite-bg {
    width: 100%;
    height: 337px;
  }
  .content {
    width: 100%;
    position: relative;
    padding-top: 55px;
    .card-box {
      width: calc(100% - 30px);
      margin-left: 15px;
      position: absolute;
      top: -80px;
      .line {
        width: 100%;
        height: 18px;
        background: #f28a49;
        border-radius: 8px 8px 8px 8px;

        box-sizing: border-box;
        padding: 4px;
        // background: linear-gradient(138deg, #ffe0a8 0%, #ffa700 100%);
        .line-c {
          width: 100%;
          height: 100%;
          background-color: #f28a49;
          border-radius: 8px;
        }
        background-color: #ffe0a8;
      }
      .card-c-box {
        width: calc(100% - 30px);
        min-height: 110px;
        background: #fffbf5;
        box-shadow: inset 3px 3px 6px 0px #fff5e5;
        border-radius: 0px 0px 8px 8px;
        border: 2px solid #ffffff;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        top: -7px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        .img-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 30px;
          }
          .name {
            font-size: 10px;
            line-height: 16px;
            margin-top: 9px;
          }
        }
        .myInvite-arrow {
          width: 14px;
        }
      }
    }
    .card-box2 {
      width: calc(100% - 60px);
      margin-left: 30px;
      background-color: #ffff;
      //   height: 100px;
      //   margin-top: 120px;
      padding-bottom: 22px;
      .card-box2-title {
        width: 190px;
        height: 30px;
        background-image: url("../../assets//image/myInvite-bg2.png");
        background-size: 100% 100%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        color: #fff;
        line-height: 30px;
        font-size: 14px;
      }
      .invited-box {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 14px;
        .invited-box-left {
          display: flex;
          flex-direction: column;
          text-align: center;
          .number {
            color: #232323;
            font-size: 14px;
            span {
              font-weight: bolder;
              font-size: 36px;
              margin-right: 7px;
            }
          }
          .name {
            color: #232323;
            font-size: 14px;
            margin-top: 5px;
          }
        }
        .invited-box-right {
          display: flex;
          flex-direction: column;
          text-align: center;
          .number {
            color: #232323;
            font-size: 14px;
            span {
              font-weight: bolder;
              font-size: 36px;
              margin-right: 7px;
            }
          }
        }
        .invited-box-line {
          width: 1px;
          height: 29px;
          background: #ededed;
        }
      }
      .invited-list-title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 18px;
        .invited-list-img {
          width: 38px;
          height: 1px;
        }
        .name {
          color: #ff7f00;
          font-size: 14px;
          margin: 0 14px;
        }
      }
      .invited-list {
        width: calc(100% - 18px);
        background: #fff7f2;
        border-radius: 4px 4px 4px 4px;
        margin-left: 9px;
        margin-top: 23px;
        padding: 15px 10px;
        .invited-list-herder {
          display: flex;
          justify-content: space-between;
          color: #232323;
          font-size: 12px;

          .left {
            display: flex;
            .paiming {
              width: 26px;
              margin-right: 20px;
            }
            .user-id {
              width: 120px;
            }
          }
          .right {
            text-align: right;
          }
        }
        .invited-list-item {
          display: flex;
          justify-content: space-between;
          color: #232323;
          font-size: 12px;
          margin-top: 15px;
          .left {
            display: flex;
            .paiming {
              width: 26px;
              margin-right: 20px;
              display: flex;
              justify-content: center;
              img {
                width: 26px;
              }
            }
            .user-id {
              width: 120px;
              display: flex;
              align-items: center;
              img {
                width: 30px;
                height: 30px;
                border-radius: 15px;
                margin-right: 14px;
              }
            }
          }
          .right {
            text-align: right;
          }
        }
      }
      p {
        margin-top: 14px;
        color: #232323;
        font-size: 14px;
      }
    }
    .card-box3 {
      margin-top: 15px;
      padding: 0 16px;
      padding-bottom: 20px;
      .card-box-name {
        color: #232323;
        font-size: 14px;
        margin-top: 27px;
      }
      .copy-box {
        width: 100%;
        height: 42px;
        background: rgba(255, 237, 224, 0.4);
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #ffe3b2;
        margin-top: 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px;
        .copy {
          flex-shrink: 0;
          width: 71px;
          height: 25px;
          background: linear-gradient(95deg, #ffa200 0%, #ff6017 100%);
          border-radius: 13px 13px 13px 13px;
          font-size: 13px;
          text-align: center;
          line-height: 25px;
          color: #fff;
          margin-left: 9px;
        }
        .copy-content {
          width: 100%;

          white-space: nowrap; // 强制一行显示
          overflow: hidden; // 超出隐藏
          text-overflow: ellipsis; // 省略号
        }
      }
      .QRCode {
        width: 150px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
      .tips {
        color: #232323;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}
</style>
